import { effect, EffectRef, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

import { Observable, of, catchError, finalize, tap } from 'rxjs';

import { ClientUserSetting } from '@core/models/clientUserSetting';
import { ApiService } from '@core/services/api.service';
import { AuthService } from '@core/services/auth/auth.service';
import { DashboardTokenService } from '@core/services/dashboard-token.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingService {
  private apiService: ApiService = inject(ApiService);
  private authService: AuthService = inject(AuthService);
  private dashboardTokenService: DashboardTokenService = inject(DashboardTokenService);

  private _authEffect: EffectRef = effect(
    () => {
      if (this.authService.authenticatedOrDashboardTokenSet()) {
        this.getUserSettingFromApi();
      } else {
        this.currentUserSetting.set(UserSettingService.defaultUserSetting);
        this.requestInProgress = false;
      }
    },
    { allowSignalWrites: true }
  );

  private requestInProgress: boolean = false;

  private static defaultUserSetting: ClientUserSetting = {
    id: 'a2fdb3dc-4b8a-45c8-9e54-e0fe5620c260',
    dashboardFontSizeInPixel: 15,
    dashboardShowPageDurationInSeconds: 10,
    dashboardLines: 15,
    divonoFontSizeInPixel: 10,
    divonoShowPageDurationInSeconds: 15,
    divonoLines: 20,
    divonoFlashMessageDurationInSeconds: 5,
    divonoUpdateMessageDurationInSeconds: 5,
    divonoCancelMessageDurationInSeconds: 30,
    divonoDeleteMessageDurationInSeconds: 120,
    divonoFlashMessageColor: '',
    divonoUpdateMessageColor: '',
    divonoCancelMessageColor: '',
    divonoDeleteMessageColor: '',
    showDivonoDQL: true,
    showDivonoZPT: true,
    showDivonoTSN: true,
    showDivonoFRN: true,
    showDivonoFZG: true,
    showDivonoKLN: true,
    showDivonoABT: true,
    showDivonoPAT: true,
    showDivonoDGN: true,
    showDivonoINS: true,
    showDivonoABC: true,
    showDivonoINF: true,
    playDivonoSound: true,
    insColorNo: '#689f39',
    insColorYes: '#fdc02a',
    insColorHigh: '#d32f2e',
    airwayColorNo: '#689f39',
    airwayColorYes: '#fdc02a',
    airwayColorHigh: '#d32f2e',
    breathingColorNo: '#689f39',
    breathingColorYes: '#fdc02a',
    breathingColorHigh: '#d32f2e',
    circulationColorNo: '#689f39',
    circulationColorYes: '#fdc02a',
    circulationColorHigh: '#d32f2e',
    disabilityColorNo: '#689f39',
    disabilityColorYes: '#fdc02a',
    disabilityColorHigh: '#d32f2e',
    showEmergencyCareOffDuty: true,
    showDeviceOffDuty: true,
    showDeviceDependableEmergencyCare: true,
    showAffiliatedDepartment: true,
    radius: 50
  };

  public currentUserSetting: WritableSignal<ClientUserSetting> = signal(UserSettingService.defaultUserSetting);
  public currentUserSetting$: Observable<ClientUserSetting> = toObservable(this.currentUserSetting);
  private _customUserSettingsEffect: EffectRef = effect(() => this.customUserSettings.set(this.currentUserSetting()), {
    allowSignalWrites: true
  });
  public customUserSettings: WritableSignal<ClientUserSetting> = signal(this.currentUserSetting());

  public getUserSettingFromApi(): void {
    if (this.requestInProgress) {
      return;
    }
    this.requestInProgress = true;
    const dashboardToken = this.dashboardTokenService.dashboardToken();
    this.apiService
      .get(dashboardToken ? '/user/setting/token/' + dashboardToken : '/user/setting')
      .pipe(
        catchError(() => {
          return of(UserSettingService.defaultUserSetting);
        }),
        tap((userSetting: ClientUserSetting) => this.currentUserSetting.set(userSetting)),
        finalize(() => (this.requestInProgress = false))
      )
      .subscribe();
  }

  public changeClientUserSetting(clientUserSetting: Partial<ClientUserSetting>): void {
    if (
      clientUserSetting.dashboardFontSizeInPixel <= 0 ||
      clientUserSetting.dashboardShowPageDurationInSeconds <= 0 ||
      clientUserSetting.dashboardLines <= 0 ||
      clientUserSetting.radius <= 0
    ) {
      return;
    }
    this.apiService
      .post('/user/setting', clientUserSetting)
      .pipe(tap((clientUserSetting: ClientUserSetting) => this.currentUserSetting.set(clientUserSetting)))
      .subscribe();
  }
}
